import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useConfigurationStateManager } from '../hooks';

import styles from './ConfigurationsZap.module.scss';
import { ConfigurationsZapForm } from './ConfigurationsZapForm';

import { Plus } from 'assets';
import { webApplicationFormBaseAuthInitialState } from 'components/Configurations/Zap/constants';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ConfigurableRow } from 'components/SecretsManagementDialog/Components/ConfigurableRow';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useGetAreSettingsProperlySet } from 'context/ConfigurationsContext/hooks/useGetAreSettingsProperlySet';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import colors from 'themes/colors.module.scss';
import { IConfigurations, ZapApplication, ZapAuthenticationConfigType } from 'types/interfaces';
import { IConfigurableItem } from 'types/interfaces/Configurations/IConfigurableItem';

interface Props {
  type: ZapAuthenticationConfigType;
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
  planItemSlug: string;
  onChangeConfiguration: Dispatch<SetStateAction<IConfigurations>>;
}

export const ConfigurationsZap: FC<Props> = ({ type, setIsDoneStep, planItemSlug, onChangeConfiguration }) => {
  const { plans } = usePlansContext();
  const { getAreSettingsProperlySet } = useGetAreSettingsProperlySet();
  const { setConfigurations } = useConfigurationsContext();
  const { getCurrentConfigurations, handleDeleteConfiguration } = useConfigurationStateManager(type, onChangeConfiguration);

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<ZapApplication>();
  const [applicationNameBeforeEditing, setApplicationNameBeforeEditing] = useState('');

  const zapApplications: ZapApplication[] = useMemo(() => getCurrentConfigurations(), [getCurrentConfigurations]);
  const configurableItems: IConfigurableItem[] = useMemo(() => zapApplications.map((config) => ({ name: config.application_name })), [zapApplications]);
  const defaultSelectedApplication = useMemo(() => webApplicationFormBaseAuthInitialState(type), [type]);

  const isDeleteDisabled: boolean = useMemo(() => zapApplications.length === 1, [zapApplications]);
  const deleteTooltip = isDeleteDisabled ? 'configurations.zap.minimumApplicationsTooltip' : undefined;

  const planItem = useMemo(() => {
    const foundPlan = Object.values(plans).find((plan) => plan.items && plan.items[planItemSlug]);
    return foundPlan ? foundPlan.items?.[planItemSlug] : undefined;
  }, [plans, planItemSlug]);
  const isMissingConfig = useMemo(() => planItem && !getAreSettingsProperlySet(planItem), [planItem, getAreSettingsProperlySet]);

  useEffect(() => {
    setIsDoneStep(!isMissingConfig);
  }, [isMissingConfig, setIsDoneStep]);

  const handleCreateNewConfiguration = useCallback(() => {
    setIsEditMode(true);
    setSelectedApplication(defaultSelectedApplication);
  }, [defaultSelectedApplication, setIsEditMode]);

  const handleCancel = useCallback(() => {
    setSelectedApplication(undefined);
    setIsEditMode(false);
  }, []);

  const handleEditConfiguration = useCallback((configurableItem: IConfigurableItem) => {
    const appToEdit = zapApplications.find((app) => app.application_name === configurableItem.name);

    if (!appToEdit) return;

    setSelectedApplication(appToEdit);
    setIsEditMode(true);
    setApplicationNameBeforeEditing(appToEdit.application_name || '');
  }, [zapApplications]);

  const handleSaveApplication = useCallback(() => {
    if (selectedApplication) {
      setConfigurations((prev) => ({
        ...prev,
        applications: [
          ...(prev.applications || []).filter(
            (app) => app.application_name !== applicationNameBeforeEditing,
          ),
          selectedApplication,
        ],
      }));
      setIsEditMode(false);
      onChangeConfiguration((prev) => ({ ...prev }));
    }
  }, [selectedApplication, setConfigurations, onChangeConfiguration, applicationNameBeforeEditing]);

  return (
    <div className={styles.configurationBoxWrapper} data-testid='ZapForm'>
      {isEditMode && selectedApplication ? (
        <ConfigurationsZapForm
          applicationNameBeforeEditing={applicationNameBeforeEditing}
          onCancel={handleCancel}
          onSave={handleSaveApplication}
          selectedApplication={selectedApplication}
          setSelectedApplication={setSelectedApplication}
          type={type}
        />
      ) : (
        <>
          <div data-testid='application-rows'>
            {configurableItems.map((item) => (
              <ConfigurableRow
                key={item.name}
                configurableItem={item}
                disableDelete={isDeleteDisabled}
                disableDeleteTooltip={deleteTooltip}
                onDelete={() => handleDeleteConfiguration('application_name', item.name)}
                onEdit={() => handleEditConfiguration(item)}
              />
            ))}
          </div>

          <div className={styles.buttons}>
            <JitButton
              className={styles.createNewApplication}
              data-testid='createApplicationButton'
              noHover
              onClick={handleCreateNewConfiguration}
              sx={{ padding: 0 }}
            >
              <JitIcon color={colors.iris} icon={Plus} size={12} />

              <JitText color={colors.iris} noWrap text='configurations.zap.addNewApplication' />
            </JitButton>
          </div>
        </>
      )}
    </div>
  );
};
