import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ResourcesTable.module.scss';
import { useGetTableColumns } from './useGetTableColumns';

import { useManagePagination } from 'components/JitTable/hooks/useManagePagination';
import { useManageTable } from 'components/JitTable/hooks/useManageTable';
import { JitTable } from 'components/JitTable/JitTable';
import { useAssetsContext } from 'context/AssetsContext';
import { constants } from 'globalConstants';
import { IAsset } from 'types/interfaces';

export const ResourcesTable: FC = () => {
  const { assets } = useAssetsContext();
  const navigate = useNavigate();
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;

  const onRowClick = (asset: IAsset) => {
    navigate(`/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}/${asset.asset_id}`);
  };
  const fetchData = (s: string | undefined) => Promise.resolve(s);
  const paginationManager = useManagePagination({
    data: assets as never[],
    fetchData,
  });
  const { columns } = useGetTableColumns();

  const tableInstance = useManageTable({
    ...paginationManager,
    columns,
    data: assets,
  });
  return (
    <div className={styles.table}>
      <JitTable
        cellPadding='0px 12px'
        className={styles.tableWrapper}
        // EmptyTableView={EmptyFindingsTable}
        handleReachScrollEnd={{
          callback: paginationManager.getNextPage,
        }}
        headerCellPadding='0'
        isLoading={paginationManager.isLoading}
        onSelectRow={onRowClick}
        rowHoverStyle={{ cursor: 'pointer' }}
        shouldDisplayWithPaper={false}
        showPagination={false}
        tableDesign='light'
        tableInstance={tableInstance}
      />

    </div>
  );
};
