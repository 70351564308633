export enum Queries {
  ScoreTrending = 'scoreTrending',
  FetchTeams = 'fetchTeams',
  ParagonEnv = 'paragonEnv',
  ParagonUser = 'paragonUser',
  TeamsLeaderboard = 'teamsLeaderboard',
  ResourceFactors = 'resourceFactors',
  PlanItemsFindingsCount = 'planItemsFindingsCount',
  FindingsCount = 'findingsCount',
  Findings = 'findings',
  UserVendors = 'userVendors',
  GitlabGroups = 'gitlabGroups',
  GitlabProjects = 'gitlabProjects',
  QuickstartChecks = 'quickstartChecks',
  AssetsStatistics = 'assetsStatistics',
  AssetFilterValues = 'assetFilterValues',
  InitialScanStats = 'initialScanStats',
}
